import React from 'react'
import Galleries from '../../components/galleries/galleries.js'
import Layout from '../../layouts/index.js'

const array = [
  '006692',
  '006708',
  '006720',
  '008071',
  '008084',
  '008111',
  '008123',
  '008129',
  '008136',
  '008153',
  '008163',
  '008179',
]

const images = array.map((image) => {
  return (
    <figure>
      <img
        alt=""
        key={image}
        src={require('../../images/gallery/falklands-and-south-georgia/' +
          image +
          '.jpg')}
      />
    </figure>
  )
})

const FalklandsAndSouthGeorgia = () => (
  <Layout>
    <section>
      <h1>Falklands and South Georgia</h1>
      {images}
      <Galleries />
    </section>
  </Layout>
)

export default FalklandsAndSouthGeorgia
